import React, { useEffect } from "react";
import Page from "../components/Page";
import Timeline from "../components/timeline/Timeline2";
import { useParams } from "react-router-dom";
import styles from "./Timeline.module.css";
import Icon from "../components/Icon";
import EditableText from "../components/EditableText";
import { logTimelinePageLoaded } from "../utils/analytics";
import TimelineOnboarding from "../features/timelineOnboarding/Onboarding";
import useTimelineExists from "../api/useTimelineExists";
import useTimelineMeta from "../api/useTimelineMeta";
import { updateTimelineMeta } from "../api";

function TimelineName({ timelineId, name }) {
    const onChange = (name) => {
        updateTimelineMeta({
            timelineId,
            props: {
                name,
            },
        });
    };

    return (
        <div className={styles.timelineName}>
            <EditableText placeholder="Name your timeline..." onChange={onChange}>
                {name}
            </EditableText>
        </div>
    );
}

function TimelinePage() {
    const { id: timelineId } = useParams();
    const [timelineExists] = useTimelineExists({ timelineId });
    const [meta] = useTimelineMeta({ timelineId });

    useEffect(() => {
        logTimelinePageLoaded({ timelineId });
    }, [timelineId]);

    if (timelineExists !== true) {
        return (
            <div className={styles.loading}>
                <Icon icon="spinner" spin />
            </div>
        );
    }

    return (
        <>
            <Page
                title={meta?.name}
                topbarContent={meta ? <TimelineName timelineId={timelineId} name={meta.name} /> : null}
                fixedFullPageHeight
            >
                <Timeline {...{ timelineId }} />
            </Page>
            <TimelineOnboarding />
        </>
    );
}

export default TimelinePage;
