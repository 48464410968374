import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

function Tooltiped({ content, children }) {
    if (!content) {
        return children;
    }

    return (
        <Tippy {...{ content }} delay={[500, 50]}>
            {children}
        </Tippy>
    );
}

export default Tooltiped;
