import React, { useCallback } from "react";
import styles from "./ItemsGroupNewItemLayer.module.css";
import useMousePosition from "@react-hook/mouse-position";
import Icon from "../Icon";
import { useStoreState } from "easy-peasy";

function NewItem({ width, cellsCount, cellWidth, mousePosition, addItem }) {
    const itemDraggingActive = useStoreState((state) => state.dnd.itemDraggingActive);

    let x = mousePosition.x;

    if (x !== null && 0 <= x && x < cellsCount * cellWidth) {
        // snap to grid
        x = Math.floor(x / cellWidth) * cellWidth;
    }

    const onNewItemClick = useCallback(() => {
        addItem({
            title: "",
            x: Math.floor(x / cellWidth),
            width: 1,
        });
    }, [addItem, cellWidth, x]);

    const style = {
        width: `${width}px`,
        left: `${x}px`,
        opacity: x === null || itemDraggingActive ? 0 : 1,
    };

    return (
        <div className={styles.newItem} {...{ style }}>
            <div className={styles.newItemInner} onClick={onNewItemClick}>
                <Icon icon="plus" />
            </div>
        </div>
    );
}

function ItemsGroupNewItemLayer({ cellsCount, cellWidth, rowHeight, addItem }) {
    const itemEditingActive = useStoreState((state) => state.itemEdit.active);
    const [mousePosition, ref] = useMousePosition();

    if (itemEditingActive) {
        return null;
    }

    return (
        <div className={styles.container} {...{ ref }}>
            <NewItem width={cellWidth} {...{ cellsCount, cellWidth, mousePosition, addItem }} />
        </div>
    );
}

export default ItemsGroupNewItemLayer;
