import { useEffect } from "react";
import styles from "./useNoBodyScroll.module.css";

function disableScrolling(enable) {
    document.body.classList.toggle(styles.noBodyScroll, enable);
}

export default function useResizeSensor() {
    useEffect(() => {
        disableScrolling(true);

        return () => disableScrolling(false);
    }, []);
}
