import React from "react";
import GenericModal from "../../components/modal/Modal";
import Header from "../../components/modal/Header";
import Content from "../../components/modal/Content";
import Footer from "../../components/modal/Footer";
import Button from "../../components/Button";

function Modal({ onClose }) {
    return (
        <GenericModal>
            <Header>Contact</Header>
            <Content>
                <p>Love to hear your feedback!</p>
                <p>Drop a message at b.fengler@gmail.com</p>
            </Content>
            <Footer>
                <Button secondary onClick={onClose}>
                    Close
                </Button>
            </Footer>
        </GenericModal>
    );
}

export default Modal;
