import { ResizeSensor as ResizeSensorVendor } from "css-element-queries";

function getSize(el) {
    return { width: el.offsetWidth, height: el.offsetHeight };
}

function isSameSize(sizeA = {}, sizeB = {}) {
    return sizeA.width === sizeB.width && sizeA.height === sizeB.height;
}

class ResizeSensor {
    constructor(el, callback) {
        this.el = el;
        this.callback = callback;
        this.sensor = new ResizeSensorVendor(el, this.onChange);

        return {
            reset: this.sensor.reset,
            detach: this.sensor.detach,
        };
    }

    onChange = () => {
        const currSize = getSize(this.el);

        if (!isSameSize(this.prevSize, currSize)) {
            this.callback(this.el, currSize);
        }

        this.prevSize = currSize;
    };
}

export default ResizeSensor;
