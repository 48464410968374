import React from "react";
import styles from "./Content.module.scss";

function Content({ children, className }) {
    const classes = [styles.content, className].filter(Boolean).join(" ");

    return <div className={classes}>{children}</div>;
}

export default Content;
