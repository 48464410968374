import React from "react";
import styles from "./Icon.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

function Icon({ shadow = false, className, ...props }) {
    let classNames = [className, shadow && styles.withShadow].filter(Boolean).join(" ");

    return <FontAwesomeIcon {...props} className={classNames} />;
}

export default Icon;
