import React from "react";
import GenericModal from "../../components/modal/Modal";
import Header from "../../components/modal/Header";
import Content from "../../components/modal/Content";
import NewTimelineForm from "./NewTimelineForm";

function NewTimelineModal({ onClose }) {
    return (
        <GenericModal>
            <Header>New timeline</Header>
            <Content>
                <NewTimelineForm onCancel={onClose} />
            </Content>
        </GenericModal>
    );
}

export default NewTimelineModal;
