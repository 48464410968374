import React from "react";
import styles from "./App.module.css";
import { StoreProvider } from "easy-peasy";
import store from "./store";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Main from "./pages/Main";
import Timeline from "./pages/Timeline";
import NotFound from "./pages/NotFound";
import ModalManager from "./components/modal/Manager";
import "./style/all";

function App() {
    return (
        <StoreProvider {...{ store }}>
            <Router>
                <div className={styles.app}>
                    <Switch>
                        <Route path="/" exact>
                            <Main />
                        </Route>
                        <Route path="/t/:id">
                            <Timeline />
                        </Route>
                        <Route path="*">
                            <NotFound />
                        </Route>
                    </Switch>
                    <ModalManager />
                </div>
            </Router>
        </StoreProvider>
    );
}

export default App;
