import { useRef, useEffect } from "react";
import ResizeSensor from "./ResizeSensor";

export default function useResizeSensor(ref, onResize, enabled) {
    const sensor = useRef();

    useEffect(() => {
        if (ref.current && enabled) {
            sensor.current = new ResizeSensor(ref.current, onResize);
        }
        return () => {
            sensor.current && sensor.current.detach && sensor.current.detach();
        };
    }, [onResize, ref, enabled]);
}
