import React, { useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import styles from "./NewTimelineForm.module.scss";
import { createTimeline } from "../../api";
import DateRangePicker from "../../components/datePicker/RangePicker";
import Checkbox from "../../components/Checkbox";
import RadioButton from "../../components/RadioButton";
import Button from "../../components/Button";
import Tooltiped from "../../components/Tooltiped";

function FormRow({ children, className }) {
    return <div className={[styles.formRow, className].filter(Boolean).join(" ")}>{children}</div>;
}

function Error({ name }) {
    return <ErrorMessage {...{ name }}>{(msg) => <div className={styles.error}>{msg}</div>}</ErrorMessage>;
}

function validate({ dateRange, precision } = {}) {
    const errors = {};

    if (!dateRange || !dateRange.startDate || !dateRange.endDate) {
        errors.dateRange = "Select date range";
    }

    if (!precision) {
        errors.precision = "Choose one of the precisions";
    }

    return errors;
}

function NewTimelineForm({ onCancel }) {
    const createTimelineAndNavigate = useCallback((values) => {
        createTimeline({
            fromDate: values.dateRange.startDate,
            toDate: values.dateRange.endDate,
            precision: values.precision,
            withWeekends: !values.excludeWeekends,
        }).then(({ id }) => {
            window.location = `/t/${id}`;
        });
    }, []);

    return (
        <Formik
            initialValues={{
                dateRange: { startDate: null, endDate: null },
                excludeWeekends: true,
                precision: "days",
            }}
            onSubmit={(values) => {
                createTimelineAndNavigate(values);
            }}
            validate={validate}
        >
            {({ isSubmitting }) => (
                <Form className={styles.form}>
                    <FormRow>
                        <Field name="dateRange">{({ field }) => <DateRangePicker {...field} />}</Field>
                        <Error name="dateRange" />
                    </FormRow>
                    <FormRow>
                        <Field name="excludeWeekends" type="checkbox">
                            {({ field }) => <Checkbox {...field}>exclude weekends</Checkbox>}
                        </Field>
                    </FormRow>
                    <FormRow>
                        <div className={styles.precisionOptions}>
                            <div className={styles.precisionOption}>
                                <Field name="precision" type="radio" value="days">
                                    {({ field }) => (
                                        <Tooltiped content="Plan with precision to days (half a day is the smallest possible item size)">
                                            <RadioButton {...field}>days precision</RadioButton>
                                        </Tooltiped>
                                    )}
                                </Field>
                            </div>
                            <div className={styles.precisionOption}>
                                <Field name="precision" type="radio" value="weeks">
                                    {({ field }) => (
                                        <Tooltiped content="Plan with precision to weeks (half a week is the smallest possible item size)">
                                            <RadioButton {...field}>weeks precision</RadioButton>
                                        </Tooltiped>
                                    )}
                                </Field>
                            </div>
                        </div>
                        <Error name="precision" />
                    </FormRow>
                    <FormRow className={styles.buttons}>
                        <Button secondary disabled={isSubmitting} onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button type="submit" disabled={isSubmitting}>
                            {isSubmitting ? "Creating..." : "Create timeline"}
                        </Button>
                    </FormRow>
                </Form>
            )}
        </Formik>
    );
}

export default NewTimelineForm;
