import React, { useEffect, useMemo } from "react";
import classNames from "classnames";
import styles from "./Page.module.scss";
import icon from "./favicon.png";
import TermsOfUseModal from "../features/termsOfUse/Modal";
import ContactModal from "../features/contact/Modal";
import { show as showModal } from "./modal/Manager";

function Link({ children, modal }) {
    return (
        <div className={styles.footerLink}>
            <span className={styles.footerLinkInner} onClick={() => showModal(modal)}>
                {children}
            </span>
        </div>
    );
}

export function Content({ children }) {
    return <div className={styles.contentBackground}>{children}</div>;
}

export default function Page({ title, topbarContent, children }) {
    useEffect(() => {
        document.title = `${title || "Timelines made easy!"} | sprintie.app`;
    }, [title]);

    const year = useMemo(() => new Date().getFullYear(), []);

    return (
        <>
            <div className={styles.topbar}>
                <a className={styles.homeLink} href="/" title="Go to main page">
                    <img src={icon} className={styles.logo} alt="logo" />
                    sprintie
                </a>
                {topbarContent}
            </div>
            <div className={classNames(styles.content)}>{children}</div>
            <div className={styles.footer}>
                <div className={styles.copyright}>Copyright © {year}, Sprintie Sp. z o.o. All rights reserved.</div>
                <div className={styles.links}>
                    <Link modal={TermsOfUseModal}>Terms of use</Link>
                    <Link modal={ContactModal}>Contact</Link>
                </div>
            </div>
        </>
    );
}
