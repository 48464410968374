import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import styles from "./EditableText.module.scss";
import TextEllipsis from "./TextEllipsis";
import Tooltiped from "./Tooltiped";
import Icon from "./Icon";

function EditBox({ children, ...otherProps }) {
    return <input className={styles.inputBox} type="text" value={children} autoFocus {...otherProps} />;
}

function EditIcon({ className }) {
    return (
        <Tooltiped content="Click to edit">
            <div className={classNames(styles.editIcon, className)}>
                <Icon icon="pencil-alt" />
            </div>
        </Tooltiped>
    );
}

function DisplayBox({ children, placeholder, isTextTruncated, onEllipsisChange }) {
    return (
        <div className={classNames(styles.displayBox, !children && styles.placeholder)}>
            <TextEllipsis onChange={onEllipsisChange}>
                {children || placeholder} <EditIcon className={classNames(isTextTruncated && styles.editIconFixed)} />
            </TextEllipsis>
        </div>
    );
}

function EditableText({ children, placeholder, onChange, wrapperClassName }) {
    const [isEditing, setIsEditing] = useState(false);
    const [editedText, setEditedText] = useState(children);
    const [isTextTruncated, setIsTextTruncated] = useState(false);
    const editEndTimestamp = useRef();

    const enterEditing = () => {
        setIsEditing(true);
    };

    const confirmEditing = () => {
        editEndTimestamp.current = Date.now();
        setIsEditing(false);
        onChange && onChange(editedText || "");
    };

    const cancelEditing = () => {
        setIsEditing(false);
        setEditedText(children);
    };

    const onKey = (event) => {
        event.key === "Enter" && confirmEditing();
        event.key === "Escape" && cancelEditing();
    };

    const onWrapperClick = () => {
        const justFinishedEditing = Date.now() - (editEndTimestamp.current || 0) <= 200;

        !isEditing && !justFinishedEditing && enterEditing();
    };

    const onInputChange = (event) => {
        setEditedText(event.target.value);
    };

    const onDisplayBoxEllipsis = ({ isTruncated }) => {
        setIsTextTruncated(isTruncated);
    };

    useEffect(() => {
        setEditedText(children);
    }, [children]);

    return (
        <div className={classNames(styles.wrapper, wrapperClassName)} onClick={onWrapperClick}>
            <Tooltiped content={isTextTruncated && !isEditing ? editedText : undefined}>
                <div className={classNames(styles.root, isEditing && styles.editing)}>
                    {isEditing ? (
                        <EditBox
                            placeholder={placeholder}
                            onBlur={confirmEditing}
                            onKeyDown={onKey}
                            onChange={onInputChange}
                        >
                            {editedText}
                        </EditBox>
                    ) : (
                        <DisplayBox
                            placeholder={placeholder}
                            isTextTruncated={isTextTruncated}
                            onEllipsisChange={onDisplayBoxEllipsis}
                        >
                            {editedText}
                        </DisplayBox>
                    )}
                </div>
            </Tooltiped>
        </div>
    );
}

export default EditableText;
