import React, { useState } from "react";
import moment from "moment";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./RangePicker.scss";

import { DateRangePicker as AirbnbDateRangePicker } from "react-dates";

function RangePicker({ value = { startDate: null, endDate: null }, name, onChange }) {
    const [focusedInput, setFocusedInput] = useState(null);

    function onDatesChange({ startDate, endDate }) {
        const event = {
            target: {
                name,
                value: {
                    ...value,
                    ...(startDate && { startDate: startDate.valueOf() }),
                    ...(endDate && { endDate: endDate.valueOf() }),
                },
            },
        };
        onChange(event);
    }

    function onFocusChange(focusedInput) {
        // console.log("onFocusChange", focusedInput);
        setFocusedInput(focusedInput);
    }

    const dates = {
        startDate: typeof value.startDate === "number" ? moment(value.startDate) : value.startDate,
        endDate: typeof value.endDate === "number" ? moment(value.endDate) : value.endDate,
    };

    return (
        <AirbnbDateRangePicker
            {...dates}
            onDatesChange={onDatesChange}
            focusedInput={focusedInput}
            onFocusChange={onFocusChange}
            minDate={moment()}
            firstDayOfWeek={1}
            numberOfMonths={2}
            displayFormat="MMM, D Y"
            startDatePlaceholderText="Start date"
            endDatePlaceholderText="End date"
            readOnly
            noBorder
        />
    );
}

export default RangePicker;
