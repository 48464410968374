import * as firebase from "firebase/app";
import "firebase/database";
import "firebase/analytics";

const firebaseConfig = {
    production: {
        apiKey: "AIzaSyDPrv3GnqMbuS-JMNm6nzVAnK9nLC-vTaM",
        authDomain: "golden-gateway-275918.firebaseapp.com",
        databaseURL: "https://golden-gateway-275918.firebaseio.com",
        projectId: "golden-gateway-275918",
        storageBucket: "golden-gateway-275918.appspot.com",
        messagingSenderId: "378080252230",
        appId: "1:378080252230:web:f99472f238c1cdab781547",
        measurementId: "G-CLD10NBRQZ",
    },

    development: {
        apiKey: "AIzaSyAGY9VlAT5eCaYCMrqOG5bInM5aCCc0OSc",
        authDomain: "sprintie-app-dev.firebaseapp.com",
        databaseURL: "https://sprintie-app-dev.firebaseio.com",
        projectId: "sprintie-app-dev",
        storageBucket: "sprintie-app-dev.appspot.com",
        messagingSenderId: "803092304821",
        appId: "1:803092304821:web:f887f0e9f298bb83647ffc",
    },
};

function getConfig() {
    const env = process.env.REACT_APP_QA ? "development" : process.env.NODE_ENV;
    return firebaseConfig[env];
}

const config = getConfig();

firebase.initializeApp(config);
const analytics = config.measurementId && firebase.analytics();

export { analytics };

export default firebase;
