import React, { useState, useEffect, useCallback } from "react";
import Page, { Content } from "../components/Page";
import styles from "./NotFound.module.css";
import { log404PageLoaded } from "../utils/analytics";

function NotFound() {
    const [counter, setCounter] = useState(3000);

    const countdown = useCallback(() => {
        setTimeout(() => {
            if (counter > 0) {
                setCounter(counter - 1000);
                countdown();
            }
        }, 1000);
    }, [counter]);

    useEffect(() => {
        countdown();
    }, [countdown]);

    useEffect(() => {
        counter === 0 &&
            setTimeout(() => {
                window.location = "/";
            }, 500);
    }, [counter]);

    useEffect(() => {
        log404PageLoaded({ url: window.location.href });
    }, []);

    return (
        <Page>
            <Content>
                <div className={styles.notFound}>Not found, redirecting to the main page in {counter / 1000} s...</div>
            </Content>
        </Page>
    );
}

export default NotFound;
