import React from "react";
import GenericModal from "../../components/modal/Modal";
import Header from "../../components/modal/Header";
import Content from "../../components/modal/Content";
import Footer from "../../components/modal/Footer";
import Button from "../../components/Button";

function Modal({ onClose }) {
    return (
        <GenericModal>
            <Header>Terms of use</Header>
            <Content>
                <p>
                    Services provided by Sprintie.app can be utilized for free. This may be subject of change in the
                    future.
                </p>
                <p>
                    There's no warranty on the availability of Sprintie.app services. This includes potential data loss.
                </p>
                <p>
                    You take full responsibility of the content you enter (subject of legal violations). Sprintie.app
                    disclaims any responsibility for any harm caused by that content.
                </p>
            </Content>
            <Footer>
                <Button secondary onClick={onClose}>
                    Close
                </Button>
            </Footer>
        </GenericModal>
    );
}

export default Modal;
