import React, { useState, useCallback, useRef, useEffect } from "react";
import styles from "./Item.module.css";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { ResizableBox } from "react-resizable";
import { moveCursorToEnd } from "../../utils/input";
import Icon from "../Icon";
import Tooltiped from "../Tooltiped";
import TextEllipsis from "../TextEllipsis";
import useItem from "../../api/useItem";
import { updateItem, removeItem } from "../../api";
import { useStoreActions } from "easy-peasy";

const ITEM_TYPE = "item";

function HookedItem(props) {
    const { timelineId, itemId } = props;
    const { item } = useItem({ timelineId, itemId });

    if (!item) {
        return null;
    }

    return <Item {...props} {...{ item }} />;
}

function Item({ cellWidth, groupIndex, timelineId, itemId, groupId, item }) {
    /**
     * Store.
     */

    const onItemDragStartedAction = useStoreActions((actions) => actions.dnd.onItemDragStarted);
    const onItemDragEndedAction = useStoreActions((actions) => actions.dnd.onItemDragEnded);

    const onItemEditStarted = useStoreActions((actions) => actions.itemEdit.onStarted);
    const onItemEditEnded = useStoreActions((actions) => actions.itemEdit.onEnded);

    /**
     * State.
     */

    const [isEditing, setIsEditing] = useState(false);
    const [editedTitle, setEditedTitle] = useState(item.title);
    const [isResizing, setIsResizing] = useState(false);
    const [editedWidth, setEditedWidth] = useState(item.width);
    const [hasTruncatedTitle, setHasTruncatedTitle] = useState(false);
    const onTitleChange = useCallback((event) => setEditedTitle(event.target.value), [setEditedTitle]);

    /**
     * Callbacks.
     */

    const updateItemCallback = useCallback(
        (props) => {
            updateItem({
                timelineId,
                itemId,
                props,
            });
        },
        [itemId, timelineId]
    );

    const enterEditing = useCallback(() => {
        setIsEditing(true);
        setHasTruncatedTitle(false);
    }, [setIsEditing, setHasTruncatedTitle]);

    const confirmEditing = useCallback(() => {
        setIsEditing(false);
        updateItemCallback({
            title: editedTitle,
        });
    }, [editedTitle, updateItemCallback]);

    const cancelEditing = useCallback(() => {
        setIsEditing(false);
        setEditedTitle(item.title);
    }, [item.title]);

    const onKey = useCallback(
        (event) => {
            event.key === "Enter" && confirmEditing();
            event.key === "Escape" && cancelEditing();
        },
        [cancelEditing, confirmEditing]
    );

    const onResize = useCallback(
        (event, { element, size, handle }) => {
            setEditedWidth(Math.round(size.width / cellWidth));
        },
        [cellWidth]
    );

    const onResizeStart = useCallback(() => setIsResizing(true), [setIsResizing]);

    const onResizeStop = useCallback(() => {
        setIsResizing(false);

        if (editedWidth === 0) {
            removeItem({ timelineId, itemsGroupId: groupId, itemId });
        } else {
            updateItemCallback({
                width: editedWidth,
            });
        }
    }, [editedWidth, timelineId, groupId, itemId, updateItemCallback]);

    const onTitleEllipsisChange = useCallback(({ isTruncated }) => setHasTruncatedTitle(isTruncated), [
        setHasTruncatedTitle,
    ]);

    /**
     * Custom hooks.
     */

    const [{ isDragging }, drag, preview] = useDrag({
        item: { cellWidth, groupIndex, timelineId, itemId, groupId, item, sourceGroupId: groupId, type: ITEM_TYPE },
        canDrag: !isEditing && !isResizing,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        begin: () => {
            onItemDragStartedAction();
        },
        end: () => {
            onItemDragEndedAction();
        },
    });

    const wrapperRef = useRef();
    const inputRef = useRef();

    /**
     * Effects.
     */

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: false });
    }, [preview]);

    useEffect(() => {
        if (isEditing) {
            const input = inputRef.current;

            input.focus();
            moveCursorToEnd(input);
        }
    }, [isEditing, inputRef]);

    useEffect(() => {
        if (isEditing) {
            onItemEditStarted();
        } else {
            onItemEditEnded();
        }
    }, [isEditing, onItemEditEnded, onItemEditStarted]);

    useEffect(() => setEditedTitle(item.title), [item.title]);

    useEffect(() => {
        /**
         * An effect to make item stick to the grid on small movements
         * (smaller than 0.5 of 1 unit of cell width). Better would be probably
         * to do the following after resizeEnd:
         *    - render only ResizableBox children, then, immediately
         *      render <ResizableBox> with the actual children.
         */
        function update() {
            if (wrapperRef && wrapperRef.current) {
                const itemElement = wrapperRef.current.querySelector(`.${styles.item}`);
                itemElement.style.width = `${item.width * cellWidth - 4}px`;
            }
        }
        isResizing === false && update();
    }, [cellWidth, isResizing, item.width, wrapperRef]);

    /**
     * Rendering.
     */

    if (isDragging) {
        return <div ref={drag} />;
    }

    const style = { left: `${item.x * cellWidth}px` };
    const computedWidth = isEditing && item.width < 4 ? 4 * cellWidth - 4 : item.width * cellWidth - 4;

    return (
        <div
            ref={wrapperRef}
            className={[
                styles.itemWrapper,
                isDragging && styles.itemWrapperDragging,
                isResizing && styles.itemWrapperResizing,
                isEditing && styles.itemWrapperEditing,
                editedWidth === 0 && styles.itemWrapperRemoving,
            ]
                .filter(Boolean)
                .join(" ")}
            {...{ style }}
            onDoubleClick={enterEditing}
        >
            <ResizableBox
                className={styles.item}
                width={computedWidth}
                height={28}
                resizeHandles={["e"]}
                onResize={onResize}
                onResizeStart={onResizeStart}
                onResizeStop={onResizeStop}
                handle={
                    isEditing ? (
                        <span />
                    ) : (
                        <div className={styles.resizer}>
                            <Tooltiped content="">
                                <div className={styles.resizerInner}>
                                    <Icon icon={editedWidth === 0 ? "times" : "angle-right"} />
                                </div>
                            </Tooltiped>
                        </div>
                    )
                }
            >
                <div ref={drag} className={styles.titleContainerWrapper}>
                    <Tooltiped content={hasTruncatedTitle && item.title}>
                        <div className={styles.titleContainer}>
                            {isEditing ? (
                                <input
                                    ref={inputRef}
                                    className={styles.input}
                                    disabled={!isEditing}
                                    type="text"
                                    value={isEditing ? editedTitle : item.title}
                                    onChange={onTitleChange}
                                    onBlur={confirmEditing}
                                    onKeyDown={onKey}
                                />
                            ) : (
                                <div className={styles.title}>
                                    <TextEllipsis onChange={onTitleEllipsisChange} deps={item.width}>
                                        {item.title}
                                    </TextEllipsis>
                                </div>
                            )}
                        </div>
                    </Tooltiped>
                </div>
            </ResizableBox>
        </div>
    );
}

export default HookedItem;
