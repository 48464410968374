import React from "react";
import Modal from "../Modal";
import Icon from "../../../components/Icon";
import styles from "./Welcome.module.css";

function Content() {
    return (
        <>
            <p className={styles.paragraph}>
                Let us share a few tips <Icon icon="lightbulb" className={styles.lightbulb} shadow /> before you start.
            </p>
        </>
    );
}

function Welcome({ onClose }) {
    return <Modal header="Welcome!" hasMoreSteps={true} content={<Content />} {...{ onClose }} />;
}

export default Welcome;
