import { createStore, action } from "easy-peasy";

const store = createStore({
    dnd: {
        itemDraggingActive: false,
        onItemDragStarted: action((state) => {
            state.itemDraggingActive = true;
        }),
        onItemDragEnded: action((state) => {
            state.itemDraggingActive = false;
        }),
    },
    itemEdit: {
        active: false,
        onStarted: action((state) => {
            state.active = true;
        }),
        onEnded: action((state) => {
            state.active = false;
        }),
    },
    // timeline: {
    //     id: null,
    //     createdAt: null,
    //     itemsGroups: {
    //         aaa: {
    //             title: null,
    //             items: {
    //                 bbb: true,
    //             },
    //         },
    //     },
    //     items: {
    //         bbb: {
    //             title: null,
    //             width: null,
    //             x: null,
    //         },
    //     },
    // },
});

export default store;
