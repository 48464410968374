import firebase from "../utils/firebase";
import { logTimelineCreated } from "../utils/analytics";

/**
 * Timeline.
 */

export function isTimelineExisting({ timelineId }) {
    return new Promise((resolve, reject) => {
        const ref = firebase.database().ref(`timelines/${timelineId}/createdAt`);
        ref.once("value", (snap) => {
            if (!snap || !snap.exists()) {
                reject({ message: "Timeline does not exist!" });
            } else {
                resolve();
            }
        });
    });
}

export async function createTimeline({ fromDate, toDate, precision, withWeekends }) {
    const { key: id } = firebase.database().ref("timelines").push();
    const timeline = {
        createdAt: Date.now(),
        itemsGroups: [],
        meta: {
            fromDate,
            toDate,
            precision,
            withWeekends,
        },
    };

    // timeline
    await firebase.database().ref(`timelines/${id}`).set(timeline);

    // sample group
    const { id: groupId } = await addItemsGroup({ timelineId: id, title: "Sample row" });

    // sample item
    await addGroupItem({
        timelineId: id,
        itemsGroupId: groupId,
        item: {
            title: "Sample item",
            x: 0,
            width: 2,
        },
    });

    logTimelineCreated({ timelineId: id });

    return {
        id,
        ...timeline,
    };
}

export function onTimelineMeta({ timelineId }, callback) {
    const ref = firebase.database().ref(`timelines/${timelineId}/meta`);

    const handler = (snapshot) => {
        if (!snapshot || !snapshot.exists()) {
            /**
             * Backward compatibility (for timelines without meta)
             */
            callback({
                fromDate: 1586124000000,
                toDate: 1587247200000,
                precision: "days",
                name: "",
                withWeekends: false,
            });
        } else {
            const meta = snapshot.val();

            /**
             * Backward compatibility (for timelines with typo "precission")
             */
            if (meta.precission) {
                meta.precision = meta.precission;
                delete meta.precission;
            }

            callback(meta);
        }
    };

    ref.on("value", handler);

    return () => ref.off("value", handler);
}

export function updateTimelineMeta({ timelineId, props = {} }) {
    const updates = {};

    Object.entries(props).forEach(([key, val]) => {
        updates[`timelines/${timelineId}/meta/${key}`] = val;
    });

    return firebase.database().ref().update(updates);
}

/**
 * Items groups.
 */

export async function addItemsGroup({ timelineId, title = "" }) {
    const group = {
        title,
        items: [],
    };
    const newItemGroupRef = firebase.database().ref(`timelines/${timelineId}/itemsGroups`).push();

    await newItemGroupRef.set(group);

    return {
        id: newItemGroupRef.key,
        ...group,
    };
}

export function onItemsGroups({ timelineId }, callback) {
    const itemsGroupsRef = firebase.database().ref(`timelines/${timelineId}/itemsGroups`);

    const handler = (snapshot) => {
        callback(snapshot.val());
    };

    itemsGroupsRef.on("value", handler);

    return () => itemsGroupsRef.off("value", handler);
}

export function onItemsGroup({ timelineId, itemsGroupId }, callback) {
    const itemsGroupRef = firebase.database().ref(`timelines/${timelineId}/itemsGroups/${itemsGroupId}`);

    const handler = (snapshot) => {
        callback(snapshot.val());
    };

    itemsGroupRef.on("value", handler);

    return () => itemsGroupRef.off("value", handler);
}

export function updateItemsGroup({ timelineId, itemsGroupId, update }) {
    const itemsGroupRef = firebase.database().ref(`timelines/${timelineId}/itemsGroups/${itemsGroupId}`);

    return itemsGroupRef.transaction(update);
}

export async function removeItemsGroup({ timelineId, itemsGroupId }) {
    const itemsGroupRef = firebase.database().ref(`timelines/${timelineId}/itemsGroups/${itemsGroupId}`);

    const snapshot = await itemsGroupRef.once("value");
    const { items } = snapshot.val();
    const remove = {};
    Object.keys(items || {}).forEach((itemId) => {
        remove[`timelines/${timelineId}/items/${itemId}`] = null;
    });
    await firebase.database().ref().update(remove);

    return itemsGroupRef.remove();
}

/**
 * Items.
 */

export function addGroupItem({ timelineId, itemsGroupId, item }) {
    const defaults = {
        title: "No name",
        items: [],
    };

    const newItemId = firebase.database().ref(`timelines/${timelineId}/items`).push().key;

    const updates = {};
    updates[`timelines/${timelineId}/items/${newItemId}`] = { ...defaults, ...item };
    updates[`timelines/${timelineId}/itemsGroups/${itemsGroupId}/items/${newItemId}`] = true;

    return firebase.database().ref().update(updates);
}

export function onItem({ timelineId, itemId }, callback) {
    const itemRef = firebase.database().ref(`timelines/${timelineId}/items/${itemId}`);

    const handler = (snapshot) => {
        callback(snapshot.val());
    };

    itemRef.on("value", handler);

    return () => itemRef.off("value", handler);
}

export function updateItem({ timelineId, itemId, sourceGroupId, targetGroupId, props = {} }) {
    const updates = {};

    if (sourceGroupId) {
        updates[`timelines/${timelineId}/itemsGroups/${sourceGroupId}/items/${itemId}`] = null;
    }
    if (targetGroupId) {
        updates[`timelines/${timelineId}/itemsGroups/${targetGroupId}/items/${itemId}`] = true;
    }
    Object.entries(props).forEach(([key, val]) => {
        updates[`timelines/${timelineId}/items/${itemId}/${key}`] = val;
    });

    return firebase.database().ref().update(updates);
}

export function removeItem({ timelineId, itemsGroupId, itemId }) {
    const updates = {};

    updates[`timelines/${timelineId}/itemsGroups/${itemsGroupId}/items/${itemId}`] = null;
    updates[`timelines/${timelineId}/items/${itemId}`] = null;

    return firebase.database().ref().update(updates);
}
