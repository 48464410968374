import React from "react";
import styles from "./LinkButton.module.scss";
import Icon from "./Icon";

function LinkButton({ icon, className, children, ...otherProps }) {
    return (
        <button type="button" className={[styles.linkButton, className].filter(Boolean).join(" ")} {...otherProps}>
            {icon && <Icon {...{ icon }} />}
            {icon && " "}
            {children}
        </button>
    );
}

export default LinkButton;
