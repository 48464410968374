import React, { useState, forwardRef } from "react";
import styles from "./RadioButton.module.scss";
import uuid from "../utils/uuid";

function RadiuButton({ name, value, checked, onChange, children, forwardRef }) {
    const [id] = useState(() => uuid());

    return (
        <div className={styles.radio} ref={forwardRef}>
            <input type="radio" {...{ id, name, value, checked, onChange }} />
            {children && <label htmlFor={id}>{children}</label>}
        </div>
    );
}

export default forwardRef((props, ref) => <RadiuButton forwardRef={ref} {...props} />);
