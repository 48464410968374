import React from "react";
import styles from "./ItemDropPreview.module.css";

function ItemDropPreview({ item, initialOffset, currentOffset, cellsCount, cellWidth, rowHeight, rowsCount }) {
    const xDelta = currentOffset.x - initialOffset.x;
    const yDelta = currentOffset.y - initialOffset.y;

    let nextX = item.item.x * cellWidth + xDelta;
    let nextY = item.groupIndex * rowHeight + yDelta;

    const minX = -25; // giving some tolerance
    const maxX = (cellsCount - 1) * cellWidth;
    const minY = 0 - rowHeight / 2 + 1;
    const maxY = rowsCount * rowHeight - rowHeight / 2 - 4;

    if (nextX < minX || nextX > maxX || nextY < minY || nextY > maxY) {
        nextX = item.item.x * cellWidth;
        nextY = item.groupIndex * rowHeight;
    } else {
        // snap coordinates to grid
        nextX = Math.round(nextX / cellWidth) * cellWidth;
        nextY = Math.round(nextY / rowHeight) * rowHeight;
    }

    const containerStyle = {
        top: `${nextY}px`,
        left: `${nextX}px`,
        height: `${rowHeight}px`,
    };

    const itemStyle = {
        width: `${item.item.width * cellWidth}px`,
    };

    return (
        <div className={styles.container} style={containerStyle}>
            <div className={styles.item} style={itemStyle}>
                <div className={styles.itemInnerWhite} />
                <div className={styles.itemInnerColor} />
            </div>
        </div>
    );
}

export default ItemDropPreview;
