import React, { useEffect, useState, useCallback } from "react";
import styles from "./TimeIndicator.module.css";
import dayjs from "../../utils/dayjs";
import classNames from "classnames";

// function compute24hourDaysProgress({ columns }) {
//     let percentage = 0;
//     const oneColumnPercentageDelta = 100 / columns.length;
//     const oneHourPercentageDelta = oneColumnPercentageDelta / 24;
//     const now = dayjs();

//     columns.forEach(({ date }) => {
//         if (now.isAfter(date, "day")) {
//             percentage += oneColumnPercentageDelta;
//         } else if (now.isSame(date, "day")) {
//             percentage += oneHourPercentageDelta * now.hour();
//         }
//     });

//     return percentage;
// }

function computeWorkingDaysProgress({ columns }) {
    let percentage = 0;
    const BEGINNING_OF_WORKING_HOURS = 9;
    const END_OF_WORKING_HOURS = 17;
    const oneColumnPercentageDelta = 100 / columns.length;
    const hoursCountInWorkingDay = END_OF_WORKING_HOURS - BEGINNING_OF_WORKING_HOURS;
    const now = dayjs();

    const endOfWorkingDay = (date) =>
        dayjs(date).set("hour", END_OF_WORKING_HOURS).set("minute", 0).set("second", 0).set("millisecond", 0);

    columns.forEach(({ date }) => {
        if (now.isAfter(endOfWorkingDay(date))) {
            percentage += oneColumnPercentageDelta;
        } else if (now.isSame(date, "day")) {
            const nowHours = now.hour() + now.minute() / 60;

            if (BEGINNING_OF_WORKING_HOURS < nowHours < END_OF_WORKING_HOURS) {
                percentage +=
                    (oneColumnPercentageDelta * (nowHours - BEGINNING_OF_WORKING_HOURS)) / hoursCountInWorkingDay;
            }
        }
    });

    return percentage;
}

function computeWeeksProgress({ columns, meta }) {
    let percentage = 0;
    const oneColumnPercentageDelta = 100 / columns.length;
    const oneDayPercentageDelta = oneColumnPercentageDelta / (meta.withWeekends ? 7 : 5);
    const oneHourPercentageDelta = oneDayPercentageDelta / 24;
    const now = dayjs();

    columns.forEach(({ date }) => {
        if (now.isAfter(date, "isoWeek")) {
            percentage += oneColumnPercentageDelta;
        } else if (now.isSame(date, "isoWeek")) {
            percentage += oneDayPercentageDelta * ((now.day() || 7) - 1) + oneHourPercentageDelta * now.hour();
        }
    });

    return percentage;
}

/**
 * Computes percentage relative to available columns.
 */
function usePercentageTimeProgress({ columns, meta, refreshFrequencyMS = 1000 * 60 * 5 }) {
    const [percentage, setPercentage] = useState(null);

    const computePercentage = useCallback(() => {
        let percentage;

        if (meta.precision === "days") {
            percentage = computeWorkingDaysProgress({ columns });
        } else if (meta.precision === "weeks") {
            percentage = computeWeeksProgress({ columns, meta });
        }

        setPercentage(percentage);
    }, [columns, meta]);

    useEffect(() => {
        computePercentage();
        const interval = setInterval(computePercentage, refreshFrequencyMS);

        return () => clearInterval(interval);
    }, [computePercentage, columns, refreshFrequencyMS]);

    return [percentage];
}

function Dot({ top, bottom }) {
    const classes = classNames(styles.dot, top && styles.dotTop, bottom && styles.dotBottom);
    return <div className={classes} />;
}

function TimeIndicator({ columns, meta, cellsCount, cellWidth, groups, rowHeight }) {
    const [percentage] = usePercentageTimeProgress({ columns, meta });

    if (percentage === null) {
        return null;
    }

    const wrapperStyle = {
        width: `${cellsCount * cellWidth}px`,
    };

    const style = {
        left: `${percentage}%`,
        height: `${groups.length * rowHeight}px`,
    };

    return (
        <div className={styles.timeIndicatorWrapper} style={wrapperStyle}>
            <div className={styles.timeIndicator} {...{ style }}>
                <Dot top={true} />
                <Dot bottom={true} />
            </div>
        </div>
    );
}

export default TimeIndicator;
