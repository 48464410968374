import React, { useState, useCallback, useEffect } from "react";
import Backdrop from "./Backdrop";

let globals = {};

function Manager() {
    const [modals, setModals] = useState([]);

    useEffect(() => {
        globals.setModals = setModals;
    }, []);

    const Modal = modals[0];

    const onModalClose = useCallback(() => {
        const nextModals = [...modals];
        nextModals.shift();
        setModals(nextModals);

        if (!nextModals.length) {
            globals.onClose && globals.onClose();
        }
    }, [modals]);

    if (!Modal) {
        return null;
    }

    return (
        <Backdrop>
            <Modal onClose={onModalClose} />
        </Backdrop>
    );
}

export const show = (modalOrModals) =>
    new Promise((res) => {
        const modals = Array.isArray(modalOrModals) ? modalOrModals : [modalOrModals];

        globals.onClose = () => res();
        globals.setModals(modals);
    });

export default Manager;
