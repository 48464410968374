import React from "react";
import { useDragLayer } from "react-dnd";
import ItemDragPreview from "./ItemDragPreview";
import ItemDropPreview from "./ItemDropPreview";

const ITEM_TYPE = "item";

function DragLayer({ cellsCount, cellWidth, rowHeight, rowsCount }) {
    const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));

    if (itemType !== ITEM_TYPE || !item || !isDragging || !currentOffset || !currentOffset) {
        return null;
    }

    return (
        <>
            <ItemDropPreview {...{ item, initialOffset, currentOffset, cellsCount, cellWidth, rowHeight, rowsCount }} />
            <ItemDragPreview {...item} {...{ currentOffset }} />
        </>
    );
}

export default DragLayer;
