import { useEffect, useState, useCallback } from "react";
import { onItemsGroup, updateItemsGroup, removeItemsGroup, addGroupItem } from ".";

export default function useItems({ timelineId, itemsGroupId }) {
    const [itemsGroup, setItemsGroup] = useState(null);

    const updateGroup = useCallback(
        (update) => {
            updateItemsGroup({ timelineId, itemsGroupId, update });
        },
        [itemsGroupId, timelineId]
    );

    const removeGroup = useCallback(() => {
        removeItemsGroup({ timelineId, itemsGroupId });
    }, [itemsGroupId, timelineId]);

    const addItem = useCallback(
        (item) => {
            addGroupItem({ timelineId, itemsGroupId, item });
        },
        [itemsGroupId, timelineId]
    );

    useEffect(() => {
        const removeListener = onItemsGroup({ timelineId, itemsGroupId }, (data) => setItemsGroup(data));

        return () => removeListener();
    }, [timelineId, itemsGroupId]);

    return { itemsGroup, updateItemsGroup: updateGroup, removeItemsGroup: removeGroup, addItem };
}
