import React, { useEffect } from "react";
import Page, { Content } from "../components/Page";
import styles from "./Main.module.scss";
import { logMainPageLoaded } from "../utils/analytics";
import Button from "../components/Button";
import { show as showModal } from "../components/modal/Manager";
import NewTimelineModal from "./main/NewTimelineModal";

function showNewTimelineModal() {
    showModal(NewTimelineModal);
}

function Main() {
    useEffect(() => {
        logMainPageLoaded();
    }, []);

    return (
        <Page>
            <Content>
                <div className={styles.columns}>
                    <div className={styles.column}>
                        <h1>Timelines made easy!</h1>
                        <p>Are you struggling with timelines drawn in spreadsheet?</p>
                        <p>Do it in a smart way.</p>
                        <p>It's easy, readable and enables collaboration. For free.</p>
                        <Button className={styles.createButton} onClick={showNewTimelineModal}>
                            Create timeline
                        </Button>
                    </div>
                    <div className={styles.column}>
                        <div className={styles.img1} />
                        <div className={styles.img2} />
                    </div>
                </div>
            </Content>
        </Page>
    );
}

export default Main;
