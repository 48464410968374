import React from "react";
import Modal from "../Modal";

function Content() {
    return (
        <>
            <p>Simply share a link to this timeline to colaborate with others.</p>
        </>
    );
}

function Enjoy({ onClose }) {
    return <Modal header="You're ready to go!" content={<Content />} {...{ onClose }} />;
}

export default Enjoy;
