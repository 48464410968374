import { useEffect, useState } from "react";
import { isTimelineExisting } from ".";

export default function useTimelineExists({ timelineId }) {
    const [timelineExists, setTimelineExists] = useState(null);

    useEffect(() => {
        isTimelineExisting({ timelineId })
            .then(() => setTimelineExists(true))
            .catch(() => {
                setTimelineExists(false);
                window.location = "/";
            });
    }, [timelineId]);

    return [timelineExists];
}
