import { useEffect, useState } from "react";
import { onItem } from ".";

export default function useItem({ timelineId, itemId }) {
    const [item, setItem] = useState(null);

    useEffect(() => {
        const removeListener = onItem({ timelineId, itemId }, (data) => setItem(data));

        return () => removeListener();
    }, [timelineId, itemId]);

    return { item };
}
