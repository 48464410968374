import { useEffect, useState } from "react";
import { onItemsGroups } from ".";

export default function useItemsGroups({ timelineId }) {
    const [itemsGroups, setItemsGroups] = useState(null);

    useEffect(() => {
        const removeListener = onItemsGroups({ timelineId }, (data) => setItemsGroups(data));

        return () => removeListener();
    }, [timelineId]);

    return [itemsGroups];
}
