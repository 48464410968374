import React, { useState } from "react";
import styles from "./Checkbox.module.scss";
import uuid from "../utils/uuid";

function Checkbox({ name, checked, onChange, children }) {
    const [id] = useState(() => uuid());

    return (
        <div className={styles.checkbox}>
            <input type="checkbox" {...{ id, name, checked, onChange }} />
            {children && <label htmlFor={id}>{children}</label>}
        </div>
    );
}

export default Checkbox;
