import React, { forwardRef } from "react";
import styles from "./IconButton.module.scss";
import Icon from "./Icon";

function IconButton({ icon, className, forwardRef, ...otherProps }) {
    return (
        <button
            ref={forwardRef}
            type="button"
            className={[styles.iconButton, className].filter(Boolean).join(" ")}
            {...otherProps}
        >
            <Icon {...{ icon }} />
        </button>
    );
}

export default forwardRef((props, ref) => <IconButton forwardRef={ref} {...props} />);
