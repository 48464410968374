import React from "react";
import Modal, { ContentImage } from "../Modal";
import imgUrl from "./RemoveItem.gif";
import { preload } from "../../../utils/image";

function Content() {
    return (
        <>
            <p>
                Simply resize it till the item becomes red. Once you release the mouse button the item will be removed
                from the board.
            </p>
            <ContentImage src={imgUrl} />
        </>
    );
}

function RemoveItem({ onClose }) {
    return <Modal header="Want to remove an item?" hasMoreSteps={true} content={<Content />} {...{ onClose }} />;
}

RemoveItem.preload = () => preload(imgUrl);

export default RemoveItem;
