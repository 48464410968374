import React, { useEffect } from "react";
import { show as showModals } from "../../components/modal/Manager";
import store from "../../utils/store";
import { logTimelineOnboardingShown, logTimelineOnboardingCompleted } from "../../utils/analytics";
import WelcomeModal from "./modals/Welcome";
import AddRowsModal from "./modals/AddRows";
import AddItemModal from "./modals/AddItem";
import RemoveItemModal from "./modals/RemoveItem";
import EnjoyModal from "./modals/Enjoy";

const STORE_KEY = "timeline-onboarding";
const shouldBeShown = !store.get(STORE_KEY);

const modals = [WelcomeModal, AddRowsModal, AddItemModal, RemoveItemModal, EnjoyModal];

function onClose() {
    store.set(STORE_KEY, Date.now());
}

function Onboarding() {
    useEffect(() => {
        if (shouldBeShown) {
            modals.forEach((modal) => modal.preload && modal.preload());

            showModals(modals).then(() => {
                onClose();
                logTimelineOnboardingCompleted();
            });

            logTimelineOnboardingShown();
        }
    }, []);

    return null;
}

export default Onboarding;
