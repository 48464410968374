import React from "react";
import styles from "./Pointer.module.css";
import Icon from "./Icon";

function Pointer({ className, pulse, ...otherProps }) {
    const classes = [className, styles.pointer, pulse && styles.pulse].filter(Boolean).join(" ");

    return <Icon icon="mouse-pointer" className={classes} {...otherProps} />;
}

export default Pointer;
