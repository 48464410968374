import React from "react";
import Modal, { ContentImage } from "../Modal";
import imgUrl from "./AddItem.gif";
import { preload } from "../../../utils/image";

function Content() {
    return (
        <>
            <p>Create an item anywhere you like in the row.</p>
            <p>Just click the floating placeholder.</p>
            <ContentImage src={imgUrl} />
            <p>Once created you can resize it or drag anywhere else.</p>
        </>
    );
}

function AddItem({ onClose }) {
    return <Modal header="Timeline items" hasMoreSteps={true} content={<Content />} {...{ onClose }} />;
}

AddItem.preload = () => preload(imgUrl);

export default AddItem;
