import React from "react";
import styles from "./Timeline2.module.scss";
import Backend from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { ExistingItemsGroup, NewItemsGroup } from "./ItemsGroup";
import HeaderCell from "./HeaderCell";
import DragLayer from "./dragLayer/DragLayer";
import useItemsGroups from "../../api/useItemsGroups";
import useTimelineMeta from "../../api/useTimelineMeta";
import dayjs from "../../utils/dayjs";
import TimeIndicator from "./TimeIndicator";

function Timeline({ timelineId, columns, meta, cellWidth }) {
    const [itemsGroups] = useItemsGroups({ timelineId });
    const groups = Object.keys(itemsGroups || []);
    const rowHeight = 60;
    const cellsCount = columns.length * 2;

    return (
        <div className={styles.timeline}>
            <div className={styles.topLeftCornerCover} />
            <div className={styles.tableWrapper}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <HeaderCell index={0} meta={meta} />
                            {columns.map(({ date }, index) => (
                                <HeaderCell index={index + 1} date={date} meta={meta} />
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {groups.map((itemsGroupId, groupIndex) => (
                            <ExistingItemsGroup
                                key={itemsGroupId}
                                id={itemsGroupId}
                                {...{
                                    timelineId,
                                    columnsCount: columns.length,
                                    cellsCount,
                                    cellWidth,
                                    rowHeight,
                                    groupIndex,
                                }}
                                extraContent={
                                    groupIndex === 0 && (
                                        <>
                                            <div className={styles.dragLayerContainer}>
                                                <DragLayer
                                                    {...{ cellsCount, cellWidth, rowHeight, rowsCount: groups.length }}
                                                />
                                            </div>
                                            <div className={styles.timeIndicatorLayerContainer}>
                                                <TimeIndicator
                                                    {...{ columns, meta, cellsCount, cellWidth, groups, rowHeight }}
                                                />
                                            </div>
                                        </>
                                    )
                                }
                            />
                        ))}
                        <NewItemsGroup {...{ timelineId, columnsCount: columns.length, cellsCount, cellWidth }} />
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function computeDaysColumns({ fromDate, precision, toDate, withWeekends }) {
    let curr = dayjs(fromDate).startOf("day");
    const to = dayjs(toDate).endOf("day");
    const columns = [];

    while (curr < to) {
        if (withWeekends || ![0, 6].includes(curr.day())) {
            columns.push({
                date: curr.valueOf(),
            });
        }

        curr = curr.add(1, "day");
    }

    return columns;
}

function computeWeeksColumns({ fromDate, precision, toDate, withWeekends }) {
    let curr = dayjs(fromDate).startOf("isoWeek");
    const to = dayjs(toDate).endOf("isoWeek");
    const columns = [];

    while (curr < to) {
        columns.push({
            date: curr.valueOf(),
        });

        curr = curr.add(1, "week");
    }

    return columns;
}

function computeColumns({ fromDate, precision, toDate, withWeekends }) {
    if (precision === "days") {
        return computeDaysColumns({ fromDate, precision, toDate, withWeekends });
    } else if (precision === "weeks") {
        return computeWeeksColumns({ fromDate, precision, toDate, withWeekends });
    } else {
        return [];
    }
}

export default function WrappedTimeline({ timelineId, cellWidth = 60 }) {
    const [meta] = useTimelineMeta({ timelineId });

    if (meta === null) {
        return null; // fetching meta
    }

    const columns = computeColumns(meta);

    /**
     * DndProvider needs to be higher than the useDrop() !!!!
     */
    return (
        <DndProvider backend={Backend}>
            <Timeline {...{ timelineId, columns, meta, cellWidth }} />
        </DndProvider>
    );
}
