import React from "react";
import styles from "./Backdrop.module.css";
import useNoBodyScroll from "../../utils/useNoBodyScroll";

function Backdrop({ children }) {
    useNoBodyScroll();

    return (
        <div className={styles.backdrop}>
            <div className={styles.content}>{children}</div>
        </div>
    );
}

export default Backdrop;
