import React, { useCallback } from "react";
import styles from "./ItemsGroup.module.scss";
import { useDrop } from "react-dnd";
import Item from "./Item";
import IconButton from "../../components/IconButton";
import LinkButton from "../../components/LinkButton";
import EditableText from "../../components/EditableText";
import { show as showConfirmation } from "../../components/ConfirmationModal";
import Tooltiped from "../Tooltiped";
import useItemsGroup from "../../api/useItemsGroup";
import { updateItem, addGroupItem, addItemsGroup } from "../../api";
import ItemsGroupNewItemLayer from "./ItemsGroupNewItemLayer";

const ITEM_TYPE = "item";

function ItemsGroup({
    columnsCount,
    cellsCount,
    cellWidth,
    rowHeight,
    groupIndex,
    timelineId,
    itemsGroupId,
    includeNewItemLayer = false,
    renderTitle,
    renderItems,
    itemsRef,
    extraContent,
}) {
    const addItem = useCallback(
        (item) => {
            addGroupItem({ timelineId, itemsGroupId, item });
        },
        [itemsGroupId, timelineId]
    );

    return (
        <tr className={styles.tableRow}>
            <th scope="row" className={styles.titleContainer}>
                {renderTitle()}
            </th>
            {[...Array(columnsCount)].map((_, index) => (
                <td key={index} className={styles.firstTableRowCell}>
                    {index === 0 && (
                        <>
                            <div
                                ref={itemsRef}
                                className={styles.items}
                                style={{ width: `${cellsCount * cellWidth}px` }}
                            >
                                {includeNewItemLayer && (
                                    <ItemsGroupNewItemLayer {...{ cellsCount, cellWidth, rowHeight, addItem }} />
                                )}
                                {renderItems({ groupIndex })}
                            </div>
                            {extraContent}
                        </>
                    )}
                </td>
            ))}
        </tr>
    );
}

export function ExistingItemsGroup({
    columnsCount,
    cellsCount,
    cellWidth,
    rowHeight,
    groupIndex,
    timelineId,
    id,
    onItemChange,
    onItemRemove,
    extraContent,
}) {
    /**
     * Custom hooks.
     */

    const [, drop] = useDrop({
        accept: ITEM_TYPE,
        drop(item, monitor) {
            const delta = monitor.getDifferenceFromInitialOffset();
            const newX = item.item.x + Math.round(delta.x / cellWidth);

            if (newX < 0 || newX >= cellsCount) {
                return; // out of range
            }

            updateItem({
                timelineId,
                itemId: item.itemId,
                sourceGroupId: item.sourceGroupId,
                targetGroupId: id,
                props: {
                    x: newX,
                },
            });

            return undefined;
        },
    });

    const { itemsGroup, updateItemsGroup, removeItemsGroup } = useItemsGroup({ timelineId, itemsGroupId: id });

    /**
     * Callbacks.
     */

    const onTitleChange = useCallback(
        (newTitle) => {
            updateItemsGroup((group) => {
                group.title = newTitle;
                return group;
            });
        },
        [updateItemsGroup]
    );

    /**
     * Render.
     */

    if (!itemsGroup) {
        return null;
    }

    return (
        <ItemsGroup
            {...{
                columnsCount,
                cellsCount,
                cellWidth,
                rowHeight,
                groupIndex,
                includeNewItemLayer: true,
                timelineId,
                itemsGroupId: id,
                renderTitle: () => (
                    <div>
                        <EditableText
                            wrapperClassName={styles.titleEditableTextWrapper}
                            onChange={onTitleChange}
                            placeholder="Name this row..."
                        >
                            {itemsGroup.title}
                        </EditableText>
                        <div className={styles.actionButtons}>
                            <span className={styles.groupRemoveButton}>
                                <Tooltiped content="Remove row with all the event">
                                    <IconButton
                                        icon="trash"
                                        onClick={() => {
                                            showConfirmation({
                                                content: "Are you sure you want to remove the row with all items?",
                                                onConfirm: () => removeItemsGroup(),
                                            });
                                        }}
                                    />
                                </Tooltiped>
                            </span>
                        </div>
                    </div>
                ),
                renderItems: ({ groupIndex }) => (
                    <>
                        {itemsGroup.items &&
                            Object.keys(itemsGroup.items).map((itemId) => (
                                <Item
                                    key={itemId}
                                    {...{
                                        timelineId,
                                        itemId,
                                        groupId: id,
                                        cellsCount,
                                        cellWidth,
                                        groupIndex,
                                        onChange: onItemChange,
                                        onRemove: onItemRemove,
                                    }}
                                />
                            ))}
                    </>
                ),
                itemsRef: drop,
                extraContent,
            }}
        />
    );
}

export function NewItemsGroup({ timelineId, columnsCount, cellsCount, cellWidth }) {
    const onNewGroup = useCallback(() => {
        addItemsGroup({
            timelineId,
        });
    }, [timelineId]);

    return (
        <ItemsGroup
            {...{
                columnsCount,
                cellsCount,
                cellWidth,
                renderTitle: () => (
                    <div className={styles.newGroupTitleContainer}>
                        <LinkButton icon="plus" onClick={onNewGroup}>
                            add row...
                        </LinkButton>
                    </div>
                ),
                renderItems: () => null,
            }}
        />
    );
}
