import React from "react";
import styles from "./ItemDragPreview.module.css";
import TextEllipsis from "../../TextEllipsis";

function ItemPreview({ cellWidth, item, currentOffset }) {
    const wrapperStyle = { left: currentOffset.x + "px", top: currentOffset.y + "px" };
    const itemStyle = { width: `${item.width * cellWidth - 4}px` };

    return (
        <div className={styles.itemWrapper} style={wrapperStyle}>
            <div className={styles.item} style={itemStyle}>
                <div className={styles.titleContainerWrapper}>
                    <div className={styles.titleContainer}>
                        <div className={styles.title}>
                            <TextEllipsis deps={item.width}>{item.title}</TextEllipsis>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ItemPreview;
