import React from "react";
import GenericModal from "../../components/modal/Modal";
import Header from "../../components/modal/Header";
import Content from "../../components/modal/Content";
import Footer from "../../components/modal/Footer";
import IconButton from "../../components/IconButton";
import Pointer from "../../components/Pointer";
import styles from "./Modal.module.css";

function Modal({ header, content, hasMoreSteps = false, onClose }) {
    return (
        <GenericModal>
            <Header>{header}</Header>
            <Content className={styles.content}>{content}</Content>
            <Footer>
                <div className={styles.footer}>
                    {hasMoreSteps && <IconButton icon="chevron-right" onClick={onClose} />}
                    {!hasMoreSteps && <IconButton icon="check" onClick={onClose} className={styles.buttonDone} />}
                </div>
            </Footer>
        </GenericModal>
    );
}

export { Modal };

function ContentImage({ src, alt = "Image", pointerX, pointerY }) {
    return (
        <div className={styles.contentImage}>
            <ImageWithPointer {...{ src, alt, pointerX, pointerY }} />
        </div>
    );
}

function ImageWithPointer({ src, alt, pointerX, pointerY }) {
    const style = {
        left: pointerX,
        top: pointerY,
    };

    const withPointer = ![pointerX, pointerY].includes(undefined);

    return (
        <div className={styles.imageWithPointer}>
            <img {...{ src, alt }} className={styles.image} />
            {withPointer && <Pointer {...{ style }} pulse />}
        </div>
    );
}

export { ContentImage };

export default Modal;
