import React, { forwardRef } from "react";
import styles from "./Button.module.scss";
import Icon from "./Icon";

function Button({ icon, className, secondary, forwardRef, children, ...otherProps }) {
    return (
        <button
            ref={forwardRef}
            type="button"
            className={[styles.button, className, secondary && styles.secondary].filter(Boolean).join(" ")}
            {...otherProps}
        >
            {icon && <Icon {...{ icon }} className={styles.icon} />}
            <span className={styles.text}>{children}</span>
        </button>
    );
}

export default forwardRef((props, ref) => <Button forwardRef={ref} {...props} />);
