import React, { useRef, useState, useEffect, useCallback } from "react";
import styles from "./TextEllipsis.module.css";
import useResizeSensor from "../utils/useResizeSensor";

const TextEllipsis = ({ onChange, children, useSensor = false, deps }) => {
    const ref = useRef();

    const [state, setState] = useState({
        prevTruncated: undefined,
        truncated: undefined,
    });

    // change the detected state
    const updateTrancatedState = useCallback(
        (isTruncated) => {
            setState({
                prevTruncated: state.truncated,
                truncated: isTruncated,
            });
        },
        [state.truncated]
    );

    // check if truncated on resize
    const onResize = useCallback(() => {
        if (!ref || !ref.current) {
            return;
        }

        if (ref.current.scrollWidth > ref.current.offsetWidth) {
            updateTrancatedState(true);
        } else {
            updateTrancatedState(false);
        }
    }, [updateTrancatedState]);

    // detect resize via sensor
    useResizeSensor(ref, onResize, useSensor);

    // detect resize via children/deps change
    useEffect(() => {
        onResize();
    }, [children, onResize, deps]);

    // trigger on change once trancated state changes
    useEffect(() => {
        onChange && state.prevTruncated !== state.truncated && onChange({ isTruncated: state.truncated });
    }, [onChange, state.prevTruncated, state.truncated]);

    return (
        <span className={styles.textEllipsis} {...{ ref }}>
            {children}
        </span>
    );
};

export default TextEllipsis;
