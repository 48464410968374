import React from "react";
import styles from "./HeaderCell.module.scss";
import dayjs from "../../utils/dayjs";

export default function HeaderCell({ index, date, meta }) {
    return (
        <th scope={index === 0 ? "row" : undefined} className={styles.headerCell}>
            {index !== 0 && (
                <div className={styles.headerCellContent}>
                    {meta.precision === "days" ? <DayOfWeek {...{ date }} /> : <WeekOfYear {...{ date }} />}
                </div>
            )}
        </th>
    );
}

function DayOfWeek({ date }) {
    const day = dayjs(date);

    return (
        <>
            <div className={styles.headerCellDayOfWeek}>{day.format("ddd")}</div>
            <div className={styles.headerCellDayOfMonth}>{day.format("MMM D")}</div>
        </>
    );
}

function WeekOfYear({ date }) {
    const week = dayjs(date);

    return (
        <>
            <div className={styles.headerCellDayOfWeek}>
                <span className={styles.headerCellWeekText}>Week</span>{" "}
                <span className={styles.headerCellWeekNumber}>{week.isoWeek()}</span>
            </div>
            <div className={styles.headerCellWeekRange}>
                {week.startOf("isoWeek").format("MMM D")} - {week.endOf("isoWeek").format("MMM D")}
            </div>
        </>
    );
}
