import { analytics } from "./firebase";

export function log(eventName, eventParams = {}) {
    analytics && analytics.logEvent(eventName, eventParams);
}

export function logMainPageLoaded() {
    log("main_page_loaded");
}

export function logTimelinePageLoaded({ timelineId } = {}) {
    log("timeline_page_loaded", { timelineId });
}

export function logTimelineCreated({ timelineId } = {}) {
    log("timeline_created", { timelineId });
}

export function logTimelineOnboardingShown() {
    log("timeline_onboarding_shown");
}

export function logTimelineOnboardingCompleted() {
    log("timeline_onboarding_completed");
}

export function log404PageLoaded({ url } = {}) {
    log("404_page_loaded", { url });
}
