import React from "react";
import Modal, { ContentImage } from "../Modal";
import imgUrl from "./AddRows.gif";
import { preload } from "../../../utils/image";

function Content() {
    return (
        <>
            <p>Add rows for each team member, group of people or anything else that works for you.</p>
            <ContentImage src={imgUrl} />
        </>
    );
}

function AddRows({ onClose }) {
    return <Modal header="Timeline rows" hasMoreSteps={true} content={<Content />} {...{ onClose }} />;
}

AddRows.preload = () => preload(imgUrl);

export default AddRows;
