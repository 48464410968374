import { useEffect, useState } from "react";
import { onTimelineMeta } from ".";

/**
 * Returned object structure:
 * {
 *      fromDate: 1595808000000,
 *      name: "Sprint 56.2.0",
 *      precission: "days",
 *      toDate: 1597017600000,
 *      withWeekends: false
 * }
 */
export default function useTimelineMeta({ timelineId }) {
    const [meta, setMeta] = useState(null);

    useEffect(() => {
        const removeListener = onTimelineMeta({ timelineId }, (data) => setMeta(data));

        return () => removeListener();
    }, [timelineId]);

    return [meta];
}
