import React from "react";
import GenericModal from "./modal/Modal";
import Header from "./modal/Header";
import Content from "./modal/Content";
import Footer from "./modal/Footer";
import Button from "./Button";
import { show as showModal } from "./modal/Manager";

function ConfirmationModal({ header, content, onConfirm, onCancel }) {
    return (
        <GenericModal>
            <Header>{header}</Header>
            <Content>{content}</Content>
            <Footer>
                <Button secondary onClick={() => onCancel && onCancel()}>
                    Cancel
                </Button>
                <Button onClick={() => onConfirm && onConfirm()}>Confirm</Button>
            </Footer>
        </GenericModal>
    );
}

export function show({ header = "Confirmation", content, onConfirm, onCancel }) {
    showModal(({ onClose }) => (
        <ConfirmationModal
            {...{
                header,
                content,
                onConfirm: () => {
                    onClose();
                    onConfirm && onConfirm();
                },
                onCancel: () => {
                    onClose();
                    onCancel && onCancel();
                },
            }}
        />
    ));
}

export default ConfirmationModal;
